import axios, { type AxiosResponse } from 'axios';
import type {
  Assets,
  CrossTabsData,
  IntroduceDatasetTypes,
  SaveUploadContext,
  SaveUploadedTypes,
  SignedUploadUrl,
  TableData,
  TransformedData,
  Views
} from 'common/interfaces/interfaces';
import type { MergeDatasetsKeys } from 'mergeDatasets/MergeDatasets';
import { type Filter } from 'models/Filter';

export class ExposeClient {
  baseUrl: string;
  controller: AbortController | undefined;

  static instance: ExposeClient;

  public static getInstance(): ExposeClient {
    if (ExposeClient.instance === undefined) {
      ExposeClient.instance = new ExposeClient();
    }

    return ExposeClient.instance;
  }

  constructor() {
    const baseUrl: string =
      String(process.env.REACT_APP_MAIN_SERVICE_BASE_URL) +
      String(process.env.REACT_APP_MAIN_SERVICE_PREFIX_URI) +
      '/expose';
    this.baseUrl = baseUrl;
  }

  generateHeaders(token: string): { accept: string; Authorization: string } {
    return {
      accept: 'application/json',
      Authorization: 'Bearer ' + token
    };
  }

  async getUserModels(
    token: string,
    page = 1,
    size = 10
  ): Promise<AxiosResponse> {
    const sortBy = 'createdDate';
    const order = 'desc';
    const headers = this.generateHeaders(token);
    const response = await axios.get(
      this.baseUrl +
        `/models?page=${page}&size=${size}&sortBy=${sortBy}&order=${order}`,
      { headers }
    );
    return response;
  }

  async getUserDatasets(
    token: string,
    page = 1,
    size = 10,
    tags: string[] = []
  ): Promise<AxiosResponse> {
    const sortBy = 'createdDate';
    const order = 'desc';
    const tagsArrayString = tags
      .map((tag, index) => `&tags[${index}]=${tag}`)
      .join('');

    const headers = this.generateHeaders(token);
    const response = await axios.get(
      this.baseUrl +
        `/datasets/user?page=${page}&size=${size}&sortBy=${sortBy}&order=${order}${tagsArrayString}`,
      { headers }
    );
    return response;
  }

  async saveUploadedDataSet(
    body: SaveUploadedTypes & IntroduceDatasetTypes,
    token: string
  ): Promise<AxiosResponse> {
    const headers = this.generateHeaders(token);
    const response = await axios.post(
      this.baseUrl + '/datasets/save-dataset',
      body,
      { headers }
    );
    return response;
  }

  async mergeDatasetsKeyValidation(
    body: MergeDatasetsKeys[],
    token: string
  ): Promise<AxiosResponse> {
    const headers = this.generateHeaders(token);
    const response = await axios.post(
      this.baseUrl + '/datasets/merge-datasets',
      body,
      { headers }
    );
    return response;
  }

  async saveTransformedDataset(
    id: string,
    body: SaveUploadedTypes & TransformedData,
    token: string
  ): Promise<AxiosResponse> {
    const headers = this.generateHeaders(token);
    const response = await axios.post(
      this.baseUrl + '/view/' + id + '/save',
      body,
      { headers }
    );
    return response;
  }

  async overwriteDataset(
    id: string,
    body: SaveUploadedTypes & TransformedData,
    token: string
  ): Promise<AxiosResponse> {
    const headers = this.generateHeaders(token);
    const response = await axios.post(
      this.baseUrl + '/datasets/' + id + '/overwrite',
      body,
      { headers }
    );
    return response;
  }

  async saveTransformedView(
    datasetId: string,
    viewId: string,
    body: SaveUploadedTypes & TransformedData,
    token: string
  ): Promise<AxiosResponse> {
    const headers = this.generateHeaders(token);
    const response = await axios.post(
      this.baseUrl + `/view/${datasetId}/${viewId}/save`,
      body,
      { headers }
    );
    return response;
  }

  async saveModel(
    body: SaveUploadedTypes & IntroduceDatasetTypes,
    token: string
  ): Promise<AxiosResponse> {
    const headers = this.generateHeaders(token);
    const response = await axios.post(this.baseUrl + '/models', body, {
      headers
    });
    return response;
  }

  async deleteModel(modelId: string, token: string): Promise<AxiosResponse> {
    const headers = this.generateHeaders(token);
    const URL = `${this.baseUrl}/models/remove/${modelId}`;
    const response = await axios.get(URL, {
      headers
    });
    return response;
  }

  async deleteDataset(
    datasetId: string,
    token: string
  ): Promise<AxiosResponse> {
    const headers = this.generateHeaders(token);
    const URL = `${this.baseUrl}/datasets/remove/${datasetId}`;
    const response = await axios.get(URL, {
      headers
    });
    return response;
  }

  async deleteView(datasetId: string, token: string): Promise<AxiosResponse> {
    const headers = this.generateHeaders(token);
    const URL = `${this.baseUrl}/view/remove/${datasetId}`;
    const response = await axios.get(URL, {
      headers
    });
    return response;
  }

  async bulkDeleteDatasets(
    body: {
      datasetIds: string[];
    },
    token: string
  ): Promise<AxiosResponse> {
    const headers = this.generateHeaders(token);
    const response = await axios.post(this.baseUrl + '/datasets/remove', body, {
      headers
    });
    return response;
    return response;
  }

  async updateModel(
    body: SaveUploadedTypes,
    modelId: string,
    token: string
  ): Promise<AxiosResponse> {
    const headers = this.generateHeaders(token);
    const response = await axios.post(
      this.baseUrl + '/models/update/' + modelId,
      body,
      {
        headers
      }
    );
    return response;
  }

  async updateDataset(
    body: SaveUploadedTypes & SaveUploadContext,
    datasetId: string,
    token: string
  ): Promise<AxiosResponse> {
    const headers = this.generateHeaders(token);
    const response = await axios.post(
      this.baseUrl + '/datasets/update/' + datasetId,
      body,
      {
        headers
      }
    );
    return response;
  }

  async updateView(
    body: SaveUploadedTypes,
    datasetId: string,
    token: string
  ): Promise<AxiosResponse> {
    const headers = this.generateHeaders(token);
    const response = await axios.post(
      this.baseUrl + '/view/update/' + datasetId,
      body,
      {
        headers
      }
    );
    return response;
  }

  async predictStatus(jobId: string, token: string): Promise<AxiosResponse> {
    const headers = this.generateHeaders(token);
    const URL = `${this.baseUrl}/runs/${jobId}`;
    const response = await axios.get(URL, {
      headers
    });
    return response;
  }

  async predictResult(
    downloadId: string,
    inferenceUrl: string,
    token: string
  ): Promise<AxiosResponse> {
    const headers = this.generateHeaders(token);
    const URL = `${this.baseUrl}/model-inference/prediction-result/${downloadId}?downloadUrl=${inferenceUrl}`;
    const response = await axios.get(URL, { headers });
    return response;
  }

  async scenarioResult(
    token: string,
    body: Record<string, unknown>
  ): Promise<AxiosResponse> {
    const headers = this.generateHeaders(token);
    const response = await axios.post(
      this.baseUrl + '/playground/run-recommendations',
      body,
      { headers }
    );
    return response;
  }

  async getScenarioResult(
    downloadUrl: string,
    token: string
  ): Promise<AxiosResponse> {
    const headers = this.generateHeaders(token);
    const URL = `${this.baseUrl}/playground/download-recommendations?downloadUrl=${downloadUrl}`;
    const response = await axios.get(URL, { headers });
    return response;
  }

  async getSavedScenarios(
    modelId: string,
    target: string,
    token: string
  ): Promise<AxiosResponse> {
    const headers = this.generateHeaders(token);
    const URL = `${this.baseUrl}/models/${modelId}/get-recommendations/${target}`;
    const response = await axios.get(URL, { headers });
    return response;
  }

  async getSignedDatasetUploadUrl(
    file: File,
    token: string
  ): Promise<AxiosResponse<SignedUploadUrl, unknown>> {
    const headers: Record<string, string> = this.generateHeaders(token);
    headers['X-AIZ-DatasetName'] = file.name;
    headers['X-AIZ-DatasetSize'] = file.size.toString();
    headers['X-AIZ-DatasetType'] = file.type;

    const URL = `${this.baseUrl}/upload/url`;
    const response = await axios.get<SignedUploadUrl>(URL, { headers });
    return response;
  }

  async uploadAsset(
    uploadData: {
      link: string;
      channelId?: string;
      datasetId?: string;
      rowRemovalNullThreshold?: string;
      mode?: string;
      save?: boolean;
    },
    token: string
  ): Promise<{ data: { fileId: string; runId: string } }> {
    const headers = this.generateHeaders(token);
    const response = await axios.post(this.baseUrl + '/upload', uploadData, {
      headers
    });
    return response;
  }

  async getDatasetRows(
    token: string,
    datasetId: string,
    page = 1,
    size = 50,
    sortBy?: string,
    order?: string,
    filter?: Filter
  ): Promise<AxiosResponse<TableData, unknown>> {
    // Abort previous request if it exists
    this.controller?.abort();
    this.controller = new AbortController();

    const sortByParam = sortBy !== undefined ? `&sortBy=${sortBy}` : '';
    const orderParam = order !== undefined ? `&order=${order}` : '';
    const headers = this.generateHeaders(token);

    const filterParam = filter !== undefined ? filter.serializeFilter() : '';
    const response = await axios.get(
      this.baseUrl +
        `/datasets/${datasetId}/rows?page=${page}&size=${size}${sortByParam}${orderParam}${filterParam}`,
      { headers, signal: this.controller.signal }
    );
    return response;
  }

  async getViewsByDatasetId(
    token: string,
    datasetId: string,
    page = 1,
    size = 50,
    sortBy?: string,
    order?: string
  ): Promise<AxiosResponse<Views, unknown>> {
    // Abort previous request if it exists
    this.controller?.abort();
    this.controller = new AbortController();

    const sortByParam = sortBy !== undefined ? `&sortBy=${sortBy}` : '';
    const orderParam = order !== undefined ? `&order=${order}` : '';
    const headers = this.generateHeaders(token);
    const response = await axios.get(
      this.baseUrl +
        `/view/${datasetId}/views?page=${page}&size=${size}${sortByParam}${orderParam}`,
      { headers, signal: this.controller.signal }
    );
    return response;
  }

  async getViewRows(
    token: string,
    datasetId: string,
    viewId: string,
    page = 1,
    size = 50,
    sortBy?: string,
    order?: string,
    filter?: Filter
  ): Promise<AxiosResponse<TableData, unknown>> {
    // Abort previous request if it exists
    this.controller?.abort();
    this.controller = new AbortController();

    const sortByParam = sortBy !== undefined ? `&sortBy=${sortBy}` : '';
    const orderParam = order !== undefined ? `&order=${order}` : '';
    const headers = this.generateHeaders(token);

    const filterParam = filter !== undefined ? filter.serializeFilter() : '';
    const response = await axios.get(
      this.baseUrl +
        `/view/${datasetId}/${viewId}/rows?page=${page}&size=${size}${sortByParam}${orderParam}${filterParam}`,
      { headers, signal: this.controller.signal }
    );
    return response;
  }

  async getDatasetById(
    datasetId: string,
    token: string
  ): Promise<AxiosResponse<Assets, unknown>> {
    const headers = this.generateHeaders(token);
    const response = await axios.get(this.baseUrl + `/datasets/${datasetId}`, {
      headers
    });
    return response;
  }

  async getViewById(
    viewId: string,
    token: string
  ): Promise<AxiosResponse<Assets, unknown>> {
    const headers = this.generateHeaders(token);
    const response = await axios.get(this.baseUrl + `/view/${viewId}`, {
      headers
    });
    return response;
  }

  async checkColumnTypeChange(
    datasetId: string,
    columnTypeData: { type: string; column: string },
    token: string
  ): Promise<AxiosResponse<Assets, unknown>> {
    const headers = this.generateHeaders(token);
    const response = await axios.post(
      this.baseUrl + `/view/validate-changing/${datasetId}`,
      columnTypeData,
      {
        headers
      }
    );
    return response;
  }

  async generateCrossTabsFile(
    crossTabsData: CrossTabsData,
    token: string
  ): Promise<AxiosResponse<Assets, unknown>> {
    const headers = this.generateHeaders(token);
    const response = await axios.post(
      this.baseUrl + '/crosstabs',
      crossTabsData,
      {
        headers
      }
    );
    return response;
  }

  async generateCrossDownload(
    crossTabsUrl: string,
    token: string
  ): Promise<AxiosResponse<Assets, unknown>> {
    const headers = this.generateHeaders(token);
    const response = await axios.get(
      this.baseUrl + `/crosstabs/download?downloadUrl=${crossTabsUrl}`,
      {
        headers,
        responseType: 'blob'
      }
    );
    return response;
  }
}
